import { Button, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useWebBooking } from "hooks";

import { useTranslation } from "react-i18next";
import { isWaitList } from "utils/booking";

export interface CancelButtonProps {
    onClick: () => void;
    isLoading: boolean;
}

export default function CancelButton({ onClick, isLoading }: CancelButtonProps) {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const isWaitListBooking = isWaitList(webBooking);

    return (
        <Button
            onClick={onClick}
            variant="ghost"
            mt={ThemeSpaceVariable.Large}
            isLoading={isLoading}
            alignSelf="flex-end"
        >
            {isWaitListBooking ? t("waitList.waitListBookingCancel") : t("cancelBookingButton")}
        </Button>
    );
}
