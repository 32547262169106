import { useBreakpointValue } from "@chakra-ui/media-query";
import { Button, Flex, Icon, Icons, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

interface ISideNavigationHeader {
    onClose: () => void;
}
export function SideNavigationHeader({ onClose }: ISideNavigationHeader) {
    const { t } = useTranslation();
    const iconSize = useBreakpointValue({
        base: "24px",
        md: "30px",
    });

    return (
        <Flex
            align="center"
            w="100%"
            px={ThemeSpaceVariable.Large}
            py={ThemeSpaceVariable.Small}
            mb={ThemeSpaceVariable.Medium}
        >
            <Flex py={ThemeSpaceVariable.Medium}>
                <Button
                    aria-label={t("action_close") as string}
                    key={iconSize}
                    onClick={onClose}
                    type="button"
                    variant="secondary"
                    w={{ base: "40px", md: "56px" }}
                    h={{ base: "40px", md: "56px" }}
                >
                    <Icon icon={Icons.Close} fontSize={iconSize} />
                </Button>
            </Flex>
        </Flex>
    );
}
