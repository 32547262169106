export function LogoVipps() {
    return (
        <svg width={28} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={0.5} y={0.5} width={27} height={17} rx={2.5} fill="#fff" />
            <path
                d="m5.402 7.566.816 2.383.8-2.383h.943L6.554 11.1H5.85L4.442 7.566h.96ZM10.089 10.541c.592 0 .928-.288 1.248-.704.176-.224.4-.272.56-.144.16.128.175.368 0 .592-.464.608-1.056.976-1.808.976-.816 0-1.536-.448-2.032-1.232-.144-.208-.112-.432.048-.544.16-.112.4-.064.544.16.352.528.832.896 1.44.896Zm1.104-1.967c0 .287-.224.48-.48.48a.471.471 0 0 1-.48-.48c0-.288.224-.48.48-.48s.48.208.48.48ZM13.448 7.566v.48c.24-.336.608-.576 1.152-.576.688 0 1.487.576 1.487 1.807 0 1.296-.767 1.92-1.567 1.92-.416 0-.8-.16-1.088-.56v1.696h-.864V7.566h.88Zm0 1.76c0 .72.416 1.103.88 1.103.448 0 .896-.352.896-1.104 0-.735-.448-1.087-.896-1.087s-.88.336-.88 1.087ZM17.607 7.566v.48c.24-.336.608-.576 1.152-.576.688 0 1.488.576 1.488 1.807 0 1.296-.768 1.92-1.568 1.92-.416 0-.8-.16-1.088-.56v1.696h-.864V7.566h.88Zm0 1.76c0 .72.416 1.103.88 1.103.448 0 .896-.352.896-1.104 0-.735-.448-1.087-.896-1.087-.464 0-.88.336-.88 1.087ZM22.086 7.47c.72 0 1.232.336 1.456 1.167l-.784.128c-.016-.415-.272-.56-.656-.56-.288 0-.512.129-.512.337 0 .16.112.32.448.383l.592.112c.576.112.896.496.896 1.008 0 .768-.688 1.152-1.344 1.152-.688 0-1.456-.352-1.568-1.216l.784-.128c.048.448.32.608.768.608.336 0 .56-.128.56-.336 0-.192-.112-.336-.48-.4l-.544-.096c-.576-.112-.928-.512-.928-1.023.016-.8.736-1.136 1.312-1.136Z"
                fill="#FF5B24"
            />
            <rect x={0.5} y={0.5} width={27} height={17} rx={2.5} stroke="#000" />
        </svg>
    );
}
