import { useQuery } from "@tanstack/react-query";
import { useWebBooking } from "hooks";
import {
    BookingWindowUnit,
    PaymentMethodType,
    Section,
    UnitMetaDataExternal,
    WhiteLabelConfig,
} from "hooks/api/types";
import { useParams } from "react-router-dom";
import { get } from "./axios-base";

export interface ExternalBookingSettings {
    minGuests: number;
    maxGuests: number;
    openingTime: number;
    closingTime: number;
    companyName: string;
    genericSignature: string;
    maxGuestsInfo: string;
    defaultAmountGuests?: number;
    phoneNumber: string;
    email: string;
    sections: Section[];
    defaultExpandedSectionIds: number[];
    unitsMetaData: UnitMetaDataExternal;
    messageDescription: string;
    messagePUL: string;
    messageBookingClosed: string;
    webConditions: string;
    menuHeader: string;
    menuMessage: string;
    displayMessageBox: boolean;
    displayMenuMessageBox: boolean;
    menuMessageDescription: string;
    gdprInfo: string;
    lastGrab: boolean;
    newsletter: boolean;
    gaTrackingId: string;
    bookingWindowAsMonth: number;
    bookingWindowUnit: BookingWindowUnit;
    paymentMethods: PaymentMethodType[] | null;
    displayCompanyName: boolean;
    enableInvoicing: boolean;
    deliveryInfoDescription: string;
    themeSettings: WhiteLabelConfig;
    address: string;
    disableCancel: boolean;
}

export function useExternalBookingSettings_Not_Immutable() {
    const { unitId: unitIdUrl } = useParams();
    const webBooking = useWebBooking().data;

    // Use the unitId from the URL if it exists, otherwise use the one from the booking
    const unitId = unitIdUrl || webBooking?.unitId;

    return useQuery({
        queryKey: ["externalBookingSettings", unitId],
        queryFn: async () => {
            const response = await get(`/booking/externalBookingSettings/${unitId}`);
            return response.data as ExternalBookingSettings;
        },
        enabled: !!unitId,
        refetchOnMount: false,
    });
}
