import moment from "moment";
import { BookingWindowUnit, ExternalBookingSettings } from "old/models/externalBookingSettings";

export const getBookingWindowTimeUnit = (unit: BookingWindowUnit): "months" | "weeks" | "days" => {
    return BookingWindowUnit[unit]?.toLowerCase() as "months" | "weeks" | "days";
};

export const isDateWithinBookingWindow = (
    date: Date,
    settings: ExternalBookingSettings
): boolean => {
    const addUnit = getBookingWindowTimeUnit(settings?.bookingWindowUnit);
    return (
        moment(date).local().startOf("day") <=
        moment().subtract(1, "days").add(settings?.bookingWindowAsMonth, addUnit)
    );
};

export enum StateValue {
    Undefined,
    Closed,
    FullyBooked,
    Open,
}

/**
 * Determines if a given date should be disabled based on several criteria.
 *
 * @param date - The date to check.
 * @param externalBookingSettings - Settings related to the unit.
 * @returns {boolean} - True if the date should be disabled, false otherwise.
 */
export const shouldDisableDate = (
    date: Date,
    externalBookingSettings: ExternalBookingSettings
): boolean => {
    return !isDateWithinBookingWindow(date, externalBookingSettings);
};
