import { WebBookingContact } from "./webBookingContact";
import { WebBookingCustomer } from "./webBookingCustomer";

export enum PaymentMethodType {
    CreditCard = 0,
    Invoice = 1,
    Mobile = 6,
    Reepay = 7,
    Undefined = 999,
}

export interface WebBookingPaymentTerminalParameters {
    terminalReturnUrl: string;
    contact: WebBookingContact | null;
    customer: WebBookingCustomer | null;
    message: string;
    payMethod: PaymentMethodType;
}
