import { useParams, useSearchParams } from "react-router-dom";
import { getCurrency } from "translations/currency";

export { useBookingViewStore } from "hooks/bookingView";

export * from "api/api-hooks";

export function useCurrencyCode() {
    const [searchParams] = useSearchParams();
    const system = searchParams.get("system") ?? "";
    const currency = getCurrency(system);
    return currency;
}

export function useSystemParam() {
    const { system } = useParams();
    return system ?? "";
}

export function useBookingIdParam() {
    const { bookingId } = useParams();
    return bookingId ?? "";
}

export function useLangParam() {
    const { lang } = useParams();
    return lang ?? "";
}
