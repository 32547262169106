import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useWebBooking } from "hooks";
import { useTranslation } from "react-i18next";
import { isWaitList } from "utils/booking";

interface ICancelModal {
    isOpen: boolean;
    handleCancelModalModalClose: () => void;
    handleCancelModalCancel: () => void;
}

const CancelModal = ({
    isOpen,
    handleCancelModalModalClose,
    handleCancelModalCancel,
}: ICancelModal) => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const isWaitListBooking = isWaitList(webBooking);
    return (
        <Modal isOpen={isOpen} onClose={handleCancelModalModalClose} size="md">
            <ModalHeader
                display="flex"
                justifyContent="space-between"
                p={ThemeSpaceVariable.Medium}
            >
                <Text fontWeight="medium" fontSize={ThemeSpaceVariable.Large}>
                    {isWaitListBooking
                        ? t("waitList.waitListBookingCancel")
                        : t("confirmBookingCancel")}
                </Text>
                <ModalCloseButton />
            </ModalHeader>
            <ModalBody display="flex" flexDirection="column" p={ThemeSpaceVariable.Medium}>
                <Text
                    fontSize={ThemeFontSizeVariable.Large}
                    mt={ThemeSpaceVariable.XSmall}
                    mb={ThemeSpaceVariable.Large}
                    fontWeight="500"
                >
                    {isWaitListBooking
                        ? t("waitList.waitListAreYouSureCancel")
                        : t("areYouSureCancel")}
                </Text>

                <Button
                    variant="primary"
                    size="md"
                    alignSelf="flex-end"
                    onClick={handleCancelModalCancel}
                >
                    {isWaitListBooking
                        ? t("waitList.waitListBookingCancel")
                        : t("confirmBookingCancel")}
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default CancelModal;
