import { Box, Stack, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

interface IDatePickerLegendProps {
    waitListDays: Date[];
    fullyBookedDays: Date[];
}

const DatePickerLegend = ({ waitListDays, fullyBookedDays }: IDatePickerLegendProps) => {
    const { t } = useTranslation();
    const dot = {
        borderRadius: "50%",
        width: "6px",
        height: "6px",
        alignSelf: "center",
        marginRight: ThemeSpaceVariable.XSmall,
        marginLeft: ThemeSpaceVariable.XSmall,
    };

    return (
        <>
            {(waitListDays.length > 0 || fullyBookedDays.length > 0) && (
                <Box mt={ThemeSpaceVariable.Medium} ps={ThemeSpaceVariable.Large}>
                    {waitListDays.length > 0 && (
                        <Stack
                            bottom={ThemeSpaceVariable.XSmall}
                            direction="row"
                            spacing={ThemeSpaceVariable.XSmall}
                        >
                            <Box
                                style={{
                                    ...dot,
                                    backgroundColor: ThemeColorVariable.Warning,
                                }}
                            />
                            <Box>{t("datepicker.label.waitlistDays")}</Box>
                        </Stack>
                    )}
                    {fullyBookedDays.length > 0 && (
                        <Stack
                            bottom={ThemeSpaceVariable.XSmall}
                            direction="row"
                            spacing={ThemeSpaceVariable.XSmall}
                        >
                            <Box
                                style={{
                                    ...dot,
                                    backgroundColor: ThemeColorVariable.Error,
                                }}
                            />
                            <Box>{t("datepicker.label.fullyBookedDays")}</Box>
                        </Stack>
                    )}
                </Box>
            )}
        </>
    );
};

export default DatePickerLegend;
