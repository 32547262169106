export function LogoCard() {
    return (
        <svg width={28} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={0.5} y={0.5} width={27} height={17} rx={2.5} fill="#fff" />
            <path stroke="#000" strokeWidth={4} d="M1 6h26" />
            <path
                d="M10 14h4.5M3.992 14h2.016"
                stroke="#000"
                strokeWidth={2}
                strokeLinecap="round"
            />
            <rect x={0.5} y={0.5} width={27} height={17} rx={2.5} stroke="#000" />
        </svg>
    );
}
