import * as Immutable from "immutable";

export interface IUserContact extends Immutable.Map<string, any> {
    id: number;
    subject: string;
    name: string;
    phone: string;
    relation: string;
}

const UserContact = Immutable.Record({
    id: -1,
    subject: null,
    name: null,
    phone: null,
    relation: null,
});

export function Record(values: any): IUserContact {
    // @ts-ignore
    const record: IUserContact = <IUserContact>new UserContact(values);
    return record;
}
