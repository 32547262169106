import { Image, Link, ThemeSpaceVariable } from "caspeco-casper-ui";
import CaspecoIcon from "assets/caspecoIcon.svg";

export function PoweredByCaspeco() {
    return (
        <Link
            display="flex"
            gap={ThemeSpaceVariable.Small}
            px={ThemeSpaceVariable.Medium}
            href="https://www.caspeco.se/bokningssystem"
            isExternal
        >
            <Image src={CaspecoIcon} />
        </Link>
    );
}
