import { IWhiteLabelColors, WhiteLabelColors } from "old/models/whiteLabel/whiteLabelColors";
import { IImage, WhiteLabelImage } from "old/models/whiteLabel/whiteLabelImage";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWhiteLabelConfig {
    colors: IWhiteLabelColors;
    logo: IImage;
    coverImageThirtyTwoByNine: IImage;
    coverImageOneByOne: IImage;
}

const WhiteLabelConfigRecord = RecordFactory<IWhiteLabelConfig>({
    colors: new WhiteLabelColors(),
    logo: new WhiteLabelImage(),
    coverImageThirtyTwoByNine: new WhiteLabelImage(),
    coverImageOneByOne: new WhiteLabelImage(),
});

export class WhiteLabelConfig extends WhiteLabelConfigRecord implements IWhiteLabelConfig {
    constructor(values?: Partial<IWhiteLabelConfig>) {
        super(values);
    }
}
