export function LogoSwish() {
    return (
        <svg width={28} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={0.5} y={0.5} width={27} height={17} rx={2.5} fill="#fff" />
            <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
                <path
                    d="M11.485 14.248a5.824 5.824 0 0 0 6.967-1.513 5.818 5.818 0 0 0-.717-8.198l-1.637 1.95a4.546 4.546 0 0 1 .56 6.405c-1.287 1.534-3.406 2.046-5.173 1.356Z"
                    fill="url(#b)"
                />
                <path
                    d="M11.485 14.248a5.824 5.824 0 0 0 7.531-2.312A5.088 5.088 0 0 0 16.1 6.487a4.545 4.545 0 0 1 .56 6.405c-1.288 1.534-3.407 2.046-5.174 1.356Z"
                    fill="url(#c)"
                />
                <path
                    d="M16.504 3.741a5.824 5.824 0 0 0-6.968 1.512 5.818 5.818 0 0 0 .717 8.199l1.637-1.95a4.546 4.546 0 0 1-.56-6.406c1.287-1.533 3.407-2.045 5.174-1.355Z"
                    fill="url(#d)"
                />
                <path
                    d="M16.504 3.741a5.824 5.824 0 0 0-7.532 2.311 5.088 5.088 0 0 0 2.918 5.45 4.546 4.546 0 0 1-.56-6.406c1.287-1.533 3.407-2.045 5.174-1.355Z"
                    fill="url(#e)"
                />
            </g>
            <rect x={0.5} y={0.5} width={27} height={17} rx={2.5} stroke="#000" />
            <defs>
                <linearGradient
                    id="b"
                    x1={18.216}
                    y1={10.666}
                    x2={15.743}
                    y2={5.74}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF2131" />
                    <stop offset={1} stopColor="#FECF2C" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={15.953}
                    y1={6.487}
                    x2={11.618}
                    y2={13.992}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBC52C" />
                    <stop offset={0.264} stopColor="#F87130" />
                    <stop offset={0.561} stopColor="#EF52E2" />
                    <stop offset={1} stopColor="#661EEC" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={9.888}
                    y1={7.39}
                    x2={12.258}
                    y2={12.21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#78F6D8" />
                    <stop offset={0.266} stopColor="#77D1F6" />
                    <stop offset={0.554} stopColor="#70A4F3" />
                    <stop offset={1} stopColor="#661EEC" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={12.131}
                    y1={11.501}
                    x2={16.432}
                    y2={4.022}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#536EED" />
                    <stop offset={0.247} stopColor="#54C3EC" />
                    <stop offset={0.564} stopColor="#64D769" />
                    <stop offset={1} stopColor="#FECF2C" />
                </linearGradient>
                <clipPath id="a">
                    <path fill="#fff" transform="translate(8 3)" d="M0 0h12v12H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
