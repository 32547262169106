import { Alert } from "caspeco-casper-ui";
import { useExternalBookingSettings, useWebBooking } from "hooks";
import { useTranslation } from "react-i18next";
import { PaymentEventType } from "types/enums";

const PrepaymentEventExpiredAlert = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const settings = useExternalBookingSettings();
    const unitName = settings.unitsMetaData.name;

    const prepaymentEvent = webBooking.paymentEvents.find(
        (x) => x.type === PaymentEventType.PaymentLinkCharge
    );

    const eventName = prepaymentEvent ? "prepaymentEventExpired" : "cardregistrationEventExpired";
    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t(`payment.${eventName}`, {
                unitName,
            })}
        </Alert>
    );
};

export default PrepaymentEventExpiredAlert;
