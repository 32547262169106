import {
    Box,
    Flex,
    Heading,
    Icon,
    Icons,
    Text,
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

export function BookingSuccess() {
    const { t } = useTranslation();

    const border = `${ThemeBorderVariable.Small} ${ThemeColorVariable.Success}`;
    return (
        <Flex direction="column" alignItems="center">
            <Box
                backgroundColor={ThemeColorVariable.SuccessContainer}
                border={border}
                padding="10px"
                borderRadius="50%"
                mb={ThemeSpaceVariable.Medium}
            >
                <Icon icon={Icons.Success} fontSize="40px" color={ThemeColorVariable.Success} />
            </Box>
            <Heading
                as="h1"
                maxW="340"
                fontWeight="500"
                textAlign="center"
                mb={ThemeSpaceVariable.Small}
            >
                {t("thanks")}
            </Heading>

            <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                {t("bookingSuccessMessage")}
            </Text>
        </Flex>
    );
}
