import { useBreakpointValue } from "@chakra-ui/react";
import { Button, Icon, Icons, useDisclosure } from "caspeco-casper-ui";
import SideNavigation from "components/side-navigation/side-navigation";
import { useTranslation } from "react-i18next";

export function HamburgerButton() {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const iconSize = useBreakpointValue({
        base: "24px",
        md: "30px",
    });

    return (
        <>
            <SideNavigation onCloseSideNav={onClose} isSideNavOpen={isOpen} />
            <Button
                key={iconSize}
                aria-label={t("actionShowMore") as string}
                onClick={onOpen}
                w={{ base: "40px", md: "56px" }}
                h={{ base: "40px", md: "56px" }}
                variant="secondary"
            >
                <Icon icon={Icons.Menu} fontSize={iconSize} />
            </Button>
        </>
    );
}
