import { RecordFactory } from "old/common/modules/recordFactory";

export interface IUnitMetaData {
    cssurl: string;
    currentDBHash: number;
    id: number;
    logo: string;
    messageCompleted: string;
    messageExceededGuests: string;
    name: string;
    takeAwayWaitTimeMinutes: number;
    unitId: number;
    binaryFlags: webbookingSettingFlags;
}

export enum webbookingSettingFlags {
    None = 0,
    displayCustomerName = 1,
}

const UnitMetaDataRecord = RecordFactory<IUnitMetaData>({
    id: -1,
    currentDBHash: 0,
    unitId: -1,
    messageExceededGuests: "",
    messageCompleted: "",
    cssurl: "",
    logo: "",
    name: "",
    takeAwayWaitTimeMinutes: 0,
    binaryFlags: webbookingSettingFlags.None,
});

export class UnitMetaData extends UnitMetaDataRecord implements IUnitMetaData {
    constructor(values?: Partial<IUnitMetaData>) {
        super(values);
    }
}
