import i18n from "config/i18n";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useMobileView } from "utils";

interface DateTexts {
    iconText: string;
    dateText: string;
}

export const useDateDisplay = (date: string | Moment): DateTexts => {
    const isMobileView = useMobileView();
    const [dateTexts, setDateTexts] = useState<DateTexts>({ iconText: "", dateText: "" });

    const capitalizeFirstLetter = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        const selectedDate: Moment = moment(date).startOf("day");
        let iconText: string;
        let text: string;

        if (isMobileView) {
            iconText = capitalizeFirstLetter(selectedDate.locale(i18n.language).format("ddd"));
            text = `${iconText} ${selectedDate.locale(i18n.language).format("D MMM")}`;
        } else {
            iconText = capitalizeFirstLetter(selectedDate.locale(i18n.language).format("ddd"));
            const currentYear = moment().year();
            const selectedYear = selectedDate.year();
            const dateFormat = currentYear === selectedYear ? "D MMMM" : "D MMMM YYYY";
            text = `${iconText} ${selectedDate.locale(i18n.language).format(dateFormat)}`;
        }

        setDateTexts({ iconText, dateText: text });

        // eslint-disable-next-line
    }, [date, isMobileView, i18n.language]);

    return dateTexts;
};

export default useDateDisplay;
