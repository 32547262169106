import { useMutation } from "@tanstack/react-query";
import { post } from "api/api-methods";
import { GUID_STORAGE_KEY } from "store";

export const useCancelBooking = () => {
    const cancelBooking = async (callback: () => void) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        if (!guid) return;
        await post(`WebBooking/WebBookings/${guid}/Cancel`, {});
        localStorage.removeItem(GUID_STORAGE_KEY);
        callback();
    };

    return useMutation({
        mutationFn: cancelBooking,
        scope: {
            id: "webbooking-create",
        },
    });
};
