import { RecordFactory } from "old/common/modules/recordFactory";

export interface IImage {
    image: string;
    imageUrl: string;
    imageFilename: string;
    imageUploadData: string;
    cropRatios: CropRatio[];
}
export type CropRatio = {
    id: number;
    imageId: number;
    left: number;
    top: number;
    width: number;
    height: number;
    aspectRatio: string;
};

const WhiteLabelImageRecord = RecordFactory<IImage>({
    image: "",
    imageUrl: "",
    imageFilename: "",
    imageUploadData: "",
    cropRatios: [],
});

export class WhiteLabelImage extends WhiteLabelImageRecord implements IImage {
    constructor(values?: Partial<IImage>) {
        super(values);
    }
}
