import React from "react";
import { Icon, Icons, ThemeSpaceVariable, NavigationListButton } from "caspeco-casper-ui";

interface ISideNavigationMenu {
    onClick: () => void;
    areaLabel?: string;
    icon: Icons;
    text: string;
}

export function SideNavigationMenuButton({ onClick, areaLabel, icon, text }: ISideNavigationMenu) {
    return (
        <NavigationListButton
            onClick={onClick}
            aria-label={areaLabel || ""}
            display="Flex"
            gap={ThemeSpaceVariable.Small}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Icon icon={icon} fontSize="20px" />
            {text}
        </NavigationListButton>
    );
}
