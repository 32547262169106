import { ThemeColorValues } from "@caspeco/casper-ui-library.base-ui.theme";

/**
 * A theme for indicating lack of real theme. Color palette is greyscale.
 * Used in production for 404 and appRedirectInfo pages (where no real theme data is exists).
 */
export const fallbackTheme: ThemeColorValues = {
    background: "#f2f2f2",
    onBackground: "#262626",
    onBackgroundSubdued: "#2626267A",
    onBackgroundBorder: "#a6a6a63D",
    backgroundDisabled: "#d9d9d90F",
    onBackgroundHeader: "#595959",

    surface: "#f2f2f2",
    onSurface: "#262626",
    onSurfaceSubdued: "#f2f2f27A",
    onSurfaceBorder: "#a6a6a63D",
    surfaceDisabled: "#d9d9d90F",
    onSurfaceHeader: "#595959",

    primary: "#f2f2f2",
    primaryHover: "#8c8c8c",
    primaryActive: "#737373",
    onPrimary: "#292526",
    primaryContainer: "#ddd7d9",
    onPrimaryContainer: "#292526",

    secondary: "#8f878f",
    secondaryHover: "#776f77",
    secondaryActive: "#776f77",
    onSecondary: "#130913",
    secondaryContainer: "#ddd6dd",
    onSecondaryContainer: "#2a242a",

    accent: "#a8a8a8",
    accentHover: "#8c8c8c",
    accentActive: "#776f77",
    accentHoverAlpha: "#4115561F",
    accentActiveAlpha: "#8c8c8c29",
    onAccent: "#292526",
    accentContainer: "#ddd7d9",
    onAccentContainer: "#292526",

    // Caspeco standard colors below:
    info: "#0269FF",
    infoHover: "#0254CC",
    infoActive: "#013F99",
    onInfo: "#FFFFFF",
    infoContainer: "#E6F0FF",
    onInfoContainer: "#012A66",

    success: "#25AF72",
    successHover: "#1E8C5B",
    successActive: "#166944",
    onSuccess: "#FFFFFF",
    successContainer: "#E5F5EE",
    onSuccessContainer: "#0F462E",

    warning: "#EEB765",
    warningHover: "#BE9251",
    warningActive: "#8F6E3D",
    onWarning: "#FFFFFF",
    warningContainer: "#FDF6ED",
    onWarningContainer: "#5F4928",

    error: "#EE657A",
    errorHover: "#BE5162",
    errorActive: "#8F3D49",
    onError: "#FFFFFF",
    errorContainer: "#FDF0F2",
    onErrorContainer: "#5F2831",

    neutral: "#000000",
    neutralHover: "#2E2E2E",
    neutralActive: "#454545",
    onNeutral: "#FFFFFF",
    neutralContainer: "#F1F1F1",
    onNeutralContainer: "#000000",
};
