import StatusFailedIcon from "components/booking-status/status-icon/status-failed-icon";
import ErrorTemplate from "components/error-components/error-template";
import { useBackFromPaymentTerminal } from "hooks/api/back-from-terminal";
import { useTranslation } from "react-i18next";
import { GUID_STORAGE_KEY, useBookingStateContext } from "store";
import { getUrlParams } from "utils";

const PaymentNotCompleted = () => {
    const { t } = useTranslation();
    const backFromTerminal = useBackFromPaymentTerminal();
    const contextState = useBookingStateContext();

    const handleRetryPayment = () => {
        const { webBookingId, ChargeId, offerStartTime } = getUrlParams();

        if (ChargeId && webBookingId) {
            localStorage.setItem(GUID_STORAGE_KEY, webBookingId);
            backFromTerminal.mutate({
                state: contextState,
                chargeId: ChargeId,
                offerStartTime: offerStartTime,
            });
        }
    };

    return (
        <ErrorTemplate
            icon={<StatusFailedIcon />}
            title={t("payment.cardRegistrationCouldNotBeCompleted.title")}
            message={t("payment.cardRegistrationCouldNotBeCompleted.message")}
            btnCallback={handleRetryPayment}
            btnText={t("errorInformation.tryAgain")}
            isBtnLoading={backFromTerminal.isPending}
        />
    );
};

export default PaymentNotCompleted;
