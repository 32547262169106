import {
    Box,
    Flex,
    Image,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useExternalBookingSettings, useWebBooking } from "hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getUniqueContainerArticlesByTimeAndGroup } from "utils";
import {
    getAvailableWaitListOffer,
    hasAvailableWaitListOffers,
    isBookingCanceled,
    isWaitList,
} from "utils/booking";

interface IBookingOverviewProps {
    disableOverview: boolean;
}

const BookingOverview = ({ disableOverview: disabledState }: IBookingOverviewProps) => {
    const externalBookingSettings = useExternalBookingSettings();
    const { data: webBooking } = useWebBooking();
    const { t } = useTranslation();

    const image = externalBookingSettings?.themeSettings.coverImageOneByOne;
    const name = externalBookingSettings?.unitsMetaData.name;
    const guests = webBooking?.guests;
    const additionalTimeSlots = () => {
        if (!webBooking?.articles) return null;
        const tablesCount = webBooking.articles.filter((article) => article.isTable).count() - 1;
        return tablesCount;
    };

    const isWaitListBooking = isWaitList(webBooking);
    const hasAvailableOffers = hasAvailableWaitListOffers(webBooking);

    const getFormattedDate = () => {
        const dateFormatString =
            isWaitListBooking && !hasAvailableOffers ? t("dateTimeWithHour") : "ddd D MMMM";
        let formattedDate = webBooking.start.locale(moment.locale()).format(dateFormatString);
        formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

        if (hasAvailableOffers) {
            const offer = getAvailableWaitListOffer(webBooking).startTime;
            const uniqueResources = getUniqueContainerArticlesByTimeAndGroup(webBooking);
            const offerArticle = uniqueResources.filter((article) =>
                article.start.isSame(offer)
            )[0];
            formattedDate += t("startAndEndTime", {
                startTime: offerArticle.start.format("HH:mm"),
                endTime: offerArticle.end.format("HH:mm"),
            });
        } else if (!isWaitListBooking) {
            formattedDate += t("startAndEndTime", {
                startTime: webBooking.start.format("HH:mm"),
                endTime: webBooking.end.format("HH:mm"),
            });
        } else if (additionalTimeSlots() > 0 && isWaitListBooking) {
            formattedDate += ` +${additionalTimeSlots()}`;
        }

        return formattedDate;
    };

    return (
        <Flex direction="column" gap={ThemeSpaceVariable.Medium}>
            {isBookingCanceled(webBooking) && (
                <Text fontWeight="500" fontSize={ThemeFontSizeVariable.Large}>
                    {isWaitListBooking ? t("waitList.waitListRemoved") : t("bookingRemoved")}
                </Text>
            )}
            <Flex gap={ThemeSpaceVariable.Small} opacity={disabledState ? 0.48 : 1}>
                {image && (
                    <Image
                        src={image.imageUrl}
                        maxH="80px"
                        borderRadius={ThemeSpaceVariable.Small}
                    />
                )}
                <Box>
                    <Text fontWeight="medium" fontSize={ThemeFontSizeVariable.Large}>
                        {name}
                    </Text>
                    <Text>{t("start.numberGuests", { guests })}</Text>
                    <Text>{getFormattedDate()}</Text>
                </Box>
            </Flex>
        </Flex>
    );
};

export default BookingOverview;
