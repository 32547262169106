import { Flex, keyframes } from "@chakra-ui/react";
import {
    Icon,
    Icons,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const PaymentSpinner = () => {
    const { t } = useTranslation();
    return (
        <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            justifySelf="center"
            gap={ThemeSpaceVariable.Large}
        >
            <Flex position="relative" w="80px" h="80px" align="center" justify="center">
                <Flex
                    position="absolute"
                    w="80px"
                    h="80px"
                    borderRadius="full"
                    border="2px solid transparent"
                    borderTopColor={ThemeColorVariable.OnSurface}
                    animation={`${spin} 1s linear infinite`}
                />
                <Flex
                    w="80px"
                    h="80px"
                    borderRadius="full"
                    bg={ThemeColorVariable.SurfaceDisabled}
                    align="center"
                    justify="center"
                >
                    <Icon icon={Icons.PaymentCard} fontSize={ThemeFontSizeVariable.X3Large} />
                </Flex>
            </Flex>

            <Text fontWeight="medium" fontSize={ThemeFontSizeVariable.Large}>
                {t("confirmingPayment")}...
            </Text>
        </Flex>
    );
};

export default PaymentSpinner;
