import { DatePicker } from "@caspeco/casper-ui-library.components.datepicker";
import { Box, Icons } from "caspeco-casper-ui";
import i18n from "config/i18n";
import { add, startOfMonth } from "date-fns";
import { getDatePickerLocale } from "helpers";
import { getNonAvailableDatesForGuests, useDayStates, useExternalBookingSettings } from "hooks/api";
import { useFirstAvailableDay, useHasAvailableDaysInMonth } from "hooks/available-days";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext, useBookingStateContext } from "store";
import { isDateWithinBookingWindow, shouldDisableDate } from "utils";
import SelectDateSkeleton from "../select-date-skeleton";
import SelectionHeader from "../selection-header";
import DatePickerLegend from "./date-picker-legend";
import FirstAvailableDay from "./first-available-day";

interface ISelectDateProps {
    selectedMonth: Date;
    setSelectedMonth: (date: Date) => void;
}
export const SelectDate = ({ selectedMonth, setSelectedMonth }: ISelectDateProps) => {
    const { t } = useTranslation();
    const [datePickerLocale, setDatePickerLocale] = useState(getDatePickerLocale(i18n.language));
    const externalBookingSettings = useExternalBookingSettings().data;
    const state = useBookingStateContext();
    const { guests, date } = state;
    const { setDate } = useBookingActionsContext();
    const dayState = useDayStates(selectedMonth);

    const firstAvailableDay = useFirstAvailableDay(selectedMonth);
    const hasAvailableDaysForMonth = useHasAvailableDaysInMonth(selectedMonth);

    const goToNextAvailableDay = () => setSelectedMonth(firstAvailableDay);

    useEffect(() => {
        const setLocaleKey = (lng: string) => {
            const selectedLocaleKey = getDatePickerLocale(lng);
            setDatePickerLocale(selectedLocaleKey);
        };

        i18n.on("languageChanged", setLocaleKey);
        setLocaleKey(i18n.language);

        return () => {
            i18n.off("languageChanged", setLocaleKey);
        };
    }, []);

    if (dayState.isLoading) {
        return <SelectDateSkeleton />;
    }
    const { closedDays, waitListDays, fullyBookedDays } = getNonAvailableDatesForGuests(
        dayState.data,
        guests
    );

    const nextMonthDate = add(selectedMonth, { months: 1 });
    const nextMonth = startOfMonth(nextMonthDate);
    const isLastAvailableMonth = !isDateWithinBookingWindow(nextMonth, externalBookingSettings);
    return (
        <>
            <SelectionHeader id="chooseDateHeader" icon={Icons.Date} text={t("start.chooseDate")} />
            <Box>
                <DatePicker
                    key={selectedMonth.toString()}
                    selectedDate={date?.toDate()}
                    onDateChange={(date: Date) => setDate(date)}
                    localeString={datePickerLocale}
                    selectedMonth={selectedMonth}
                    onMonthChange={setSelectedMonth}
                    strikeThroughDates={closedDays}
                    dots={{
                        yellowDotDates: waitListDays,
                        redDotDates: fullyBookedDays,
                    }}
                    shouldDisableDate={(date: Date) =>
                        shouldDisableDate(date, externalBookingSettings)
                    }
                    disablePastMonths
                    disableForwardNavigation={isLastAvailableMonth}
                />
            </Box>
            <DatePickerLegend fullyBookedDays={fullyBookedDays} waitListDays={waitListDays} />

            {firstAvailableDay && !hasAvailableDaysForMonth && (
                <FirstAvailableDay
                    date={firstAvailableDay}
                    goToNextAvailableDay={goToNextAvailableDay}
                />
            )}
        </>
    );
};
