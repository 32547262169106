import { Box, Icon, Icons, ThemeBorderVariable, ThemeColorVariable } from "caspeco-casper-ui";

const StatusFailedIcon = () => {
    const border = `${ThemeBorderVariable.Small} ${ThemeColorVariable.Error}`;
    return (
        <Box
            backgroundColor={ThemeColorVariable.ErrorContainer}
            border={border}
            padding="5px"
            borderRadius="50%"
            marginBottom="16px"
        >
            <Icon icon={Icons.Remove} fontSize="50px" color={ThemeColorVariable.Error} />
        </Box>
    );
};

export default StatusFailedIcon;
