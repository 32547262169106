import i18n from "config/i18n";

export enum SupportedLocale {
    EN = "en-GB",
    SV = "sv-SE",
    NB = "nb-NO",
    DA = "da-DK",
    FI = "fi-FI",
    ES = "es-ES",
    ET = "et-EE",
    RU = "ru-RU",
    FR = "fr-BE",
    NL = "nl-BE",
}

enum SupportedLanguage {
    EN = "en",
    NB = "nb",
    DA = "da",
    SV = "sv",
    FI = "fi",
    ES = "es",
}

const langFromLocale = (lang: string): string => lang.split("-")[0].toLowerCase();
/**
 * Will return the locale limited to what the date picker supports.
 */
export const getDatePickerLocale = (locale: string): SupportedLocale => {
    const lang = langFromLocale(locale);
    switch (lang) {
        case SupportedLanguage.EN:
            return SupportedLocale.EN;
        case SupportedLanguage.NB:
            return SupportedLocale.NB;
        case SupportedLanguage.DA:
            return SupportedLocale.DA;
        case SupportedLanguage.SV:
            return SupportedLocale.SV;
        default:
            return SupportedLocale.EN;
    }
};

/**
 * Will return the locale based on the language.
 */
export const getLocaleFromLang = (locale: string): SupportedLocale => {
    const lang = langFromLocale(locale);
    switch (lang) {
        case SupportedLanguage.EN:
            return SupportedLocale.EN;
        case SupportedLanguage.NB:
            return SupportedLocale.NB;
        case SupportedLanguage.DA:
            return SupportedLocale.DA;
        case SupportedLanguage.SV:
            return SupportedLocale.SV;
        case SupportedLanguage.ES:
            return SupportedLocale.ES;
        default:
            return SupportedLocale.EN;
    }
};

/**
 * Helper to get translated name of current used i18n language.
 * @returns translated name of current language.
 * For ex. i18n language is "SV" the return value will be "Svenska".
 */
export const getNameOfCurrentLang = () => {
    switch (i18n.language) {
        case SupportedLanguage.EN:
            return i18n.t("miscEnglish");
        case SupportedLanguage.SV:
            return i18n.t("miscSwedish");
        case SupportedLanguage.NB:
            return i18n.t("miscNorwegian");
        case SupportedLanguage.DA:
            return i18n.t("miscDanish");
        case SupportedLanguage.FI:
            return i18n.t("miscFinnish");
        case SupportedLanguage.ES:
            return i18n.t("miscEspanol");
        default:
            return i18n.t("miscEnglish");
    }
};

export default getNameOfCurrentLang;
