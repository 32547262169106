import { List, Record } from "immutable";
import moment from "moment";

type UnknownObjectType = {
    [key: string]: unknown;
};

const convertArrayToList = (array: unknown[]) =>
    List(array.filter((v: unknown) => !!v).map(parseBackendData));
const convertObjectToRecord = (object: Object) => {
    const copy = { ...object } as UnknownObjectType;
    Object.keys(copy)
        .filter((key) => !!copy[key])
        .forEach((key) => {
            copy[key] = parseBackendData(copy[key]); //recursive call
        });
    return Record(copy)();
};
const convertStringToMomentOrString = (string: string) => {
    const m = moment(string, moment.ISO_8601, true);
    return m.isValid() ? m : string;
};
/**
 *
 * @param data any javascript object
 * @returns Data object deeply converted to immutable and moment
 */
export function parseBackendData(data: unknown): unknown {
    if (Array.isArray(data)) {
        return convertArrayToList(data);
    }
    if (typeof data === "object" && data !== null) {
        return convertObjectToRecord(data);
    }
    if (typeof data === "string") {
        return convertStringToMomentOrString(data);
    }
    return data;
}
