import i18next from "i18next";
import { CountryCode } from "libphonenumber-js/types";
import { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { SupportedLocale } from "./language-helper";

const unsupportedCharacterRegex =
    /[\d\u4e00-\u9fff\u3040-\u309f\uac00-\ud7af\u0600-\u06FF\u30A0-\u30FF]/;

export const hasUnsupportedCharacters = (value: string): boolean => {
    return unsupportedCharacterRegex.test(value);
};

const invalidCharacterForCompanyNameRegex = /[^a-zA-Z0-9 åäöÅÄÖæÆøØ]/;

export const hasInvalidCharactersForCompanyName = (value: string): boolean => {
    return invalidCharacterForCompanyNameRegex.test(value);
};

const unsupportedCharacterForMessageRegex =
    /[\u4e00-\u9fff\u3040-\u309f\uac00-\ud7af\u0600-\u06FF\u30A0-\u30FF]/;

export const hasUnsupportedCharactersForMessage = (value: string): boolean => {
    return unsupportedCharacterForMessageRegex.test(value);
};

export const validateEmail = (email: string | undefined) => {
    if (email === undefined || email.trim().length === 0) {
        return i18next.t("contact.validation.email");
    }

    const EMAIL_REGEX_PATTERN = /^\S+@\S+\.\S+$/;
    if (!EMAIL_REGEX_PATTERN.test(email)) {
        return i18next.t("contact.validation.provideValidEmail");
    }

    return true;
};

export const validateName = (name: string | undefined, fieldType: "fname" | "lname") => {
    if (!name) return i18next.t(`contact.validation.${fieldType}`);

    const trimmedName = name.trim();
    if (trimmedName.length < 2) return i18next.t(`contact.validation.${fieldType}`);
    if (trimmedName.length > 50) return i18next.t(`contact.validation.${fieldType}Exceeded`);
    if (hasUnsupportedCharacters(trimmedName))
        return i18next.t(`contact.validation.${fieldType}UnsupportedCharacters`);

    return true;
};

export const validateCustomerName = (companyName: string | undefined) => {
    if (!companyName) return i18next.t("customer.validation.name");

    const trimmedName = companyName.trim();
    if (trimmedName.length < 2) return i18next.t("customer.validation.customerNameTooShort");
    if (trimmedName.length > 50) return i18next.t("customer.validation.customerNameExceeded");
    if (hasInvalidCharactersForCompanyName(trimmedName))
        return i18next.t("contact.validation.customerNameUnsupportedCharacters");

    return true;
};

export const validateMessage = (message: string | undefined) => {
    if (!message) return true;

    const trimmedMessage = message.trim();
    if (hasUnsupportedCharactersForMessage(trimmedMessage))
        return i18next.t("contact.validation.messageUnsupportedCharacters");

    return true;
};

/**
 * Validation for phone number.
 * @returns true if valid or string with error message if not valid
 */
export const validatePhoneNumber = (
    phoneNumber: string | undefined,
    countryCode: CountryCode | undefined
): string | true => {
    // If phonenumber is undefined, clear errors
    if (!phoneNumber || !countryCode) return true;

    // Checks that phone number follows E.164 format
    const isValid = isPossiblePhoneNumber(phoneNumber);

    if (!isValid) {
        switch (countryCode) {
            case "SE":
                return i18next.t("errorFormatXIsNotValidPhoneWithSuggestion", {
                    0: phoneNumber,
                    1: "+46 70 123 45 67",
                });
            case "NO":
                return i18next.t("errorFormatXIsNotValidPhoneWithSuggestion", {
                    0: phoneNumber,
                    1: "+47 401234567",
                });
            case "DK":
                return i18next.t("errorFormatXIsNotValidPhoneWithSuggestion", {
                    0: phoneNumber,
                    1: "+45 3212 3456",
                });
            case "FI":
                return i18next.t("errorFormatXIsNotValidPhoneWithSuggestion", {
                    0: phoneNumber,
                    1: "+358 50 1234567",
                });
            default:
                return i18next.t("errorFormatXIsNotValidPhone", {
                    0: phoneNumber,
                });
        }
    }
    return true;
};

/**
 * Use to pre-select a country code for Phone number input,
 * based on existing phone number or (fallback) stored language preferences.
 */
export const getPhoneNumberCountryCode = (phoneNumber: string | undefined): CountryCode => {
    const storedLanguage = window.localStorage.getItem("i18nextLng");
    let defaultCountryCode: CountryCode = "SE"; // Always fallback to Sweden for now

    if (phoneNumber) {
        // If phonenumber exists, try to identify and use that country code
        const parsedCountry = parsePhoneNumber(phoneNumber)?.country;
        if (parsedCountry) defaultCountryCode = parsedCountry;
    } else if (storedLanguage === SupportedLocale.NB) {
        // Assume Norway as country code if the selected lang is NB
        defaultCountryCode = "NO";
    }
    return defaultCountryCode;
};
