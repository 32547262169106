import * as SystemTag from "old/application/models/management/systemTag";
import * as SystemUserInfo from "old/application/models/management/systemUserInfo";
import * as Immutable from "immutable";
import Moment from "moment-timezone";
import * as UserContact from "./userContact";
import { RecordFactory } from "old/common/modules/recordFactory";

import { LocaleIdString } from "./translation";

export enum LoginType {
    PersonalLogin = 0,
    GroupLogin = 1,
}

export enum IsoCountryCode {
    None = 0,
    SE,
    NO,
    DK,
    BE,
    EE,
    FI,
    US,
    RU,
    NL,
    ES,
}

// ----------- User -----------
export interface IUserValues {
    subject?: string;
    name?: string;
    firstname?: string;
    lastname?: string;
    mobilePhone?: string;
    email?: string;
    username?: string;
    /**
     * @enabled This is used to tell whether client is active. By that we mean "Klienter"/Klocka that is a "user". Has nothing to do with employee.enabled.
     */
    enabled?: boolean;
    lastSeen?: IMoment;
    loginType?: LoginType;
    systems?: Immutable.List<string>;
    selectedSystems?: Immutable.List<string>;
    systemUserInfo?: Immutable.List<SystemUserInfo.ISystemUserInfoRecord>;
    systemTags?: Immutable.List<SystemTag.ISystemTagRecord>;
    locale?: LocaleIdString;
    coAddress?: string;
    address?: string;
    zip?: string;
    city?: string;
    country?: string;
    phone?: string;
    imageUrl?: string;
    imageFilename?: string;
    image?: string;
    imageUploadData?: string;
    contacts?: Immutable.List<UserContact.IUserContact>;
    systemBankAccounts?: Immutable.List<ISystemBankAccount>;
    systemPinCodes?: Immutable.List<ISystemPinCode>;
    chrLogin?: string;
    disableImpersonate?: boolean;
    hasValidEmployment?: boolean;
    employeeId?: number;
    isManuallyConnectedToSystem?: boolean;
    toString: () => string;
    userTitle?: string;
}

export interface IUserRecord extends User {}

export interface ISystemBankAccount {
    disabled: boolean;
    systemName: string;
    accountNumber: string;
    ibanAccountNumber: string;
    ibanDisabled: boolean;
    systemCommonName: string;
    clearingNumber: string;
    isoCountryCode: IsoCountryCode;
    employeeId: number;
}

const SystemBankAccount = Immutable.Record({
    disabled: false,
    systemName: "",
    accountNumber: "",
    ibanAccountNumber: "",
    ibanDisabled: false,
    systemCommonName: "",
    clearingNumber: "",
    isoCountryCode: IsoCountryCode.None,
    employeeId: null,
});

export interface ISystemPinCode {
    systemName: string;
    pinCode: string;
    systemCommonName: string;
}

const SystemPinCode = Immutable.Record({
    systemName: "",
    systemCommonName: "",
    pinCode: "",
});

const UserR = RecordFactory<IUserValues>({
    subject: "",
    name: "",
    firstname: "",
    lastname: "",
    mobilePhone: "",
    email: "",
    username: "",
    enabled: true,
    lastSeen: undefined,
    loginType: undefined,
    systems: Immutable.List<string>(),
    selectedSystems: Immutable.List<string>(),
    systemUserInfo: Immutable.List<SystemUserInfo.ISystemUserInfoRecord>(),
    systemTags: Immutable.List<SystemTag.ISystemTagRecord>(),
    locale: undefined,
    coAddress: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    phone: "",
    imageUrl: "",
    imageFilename: "",
    image: "",
    imageUploadData: "",
    contacts: Immutable.List<UserContact.IUserContact>(),
    systemBankAccounts: Immutable.List<ISystemBankAccount>(),
    systemPinCodes: Immutable.List<ISystemPinCode>(),
    chrLogin: "",
    isManuallyConnectedToSystem: false,
    disableImpersonate: undefined,
    hasValidEmployment: undefined,
    employeeId: undefined,
});

export class User extends UserR implements IUserValues {
    constructor(values: Readonly<IUserValues>) {
        const modifiedValues: Partial<IUserValues> = {};
        if (values.systemUserInfo) {
            modifiedValues.systemUserInfo = Immutable.List<SystemUserInfo.ISystemUserInfoRecord>(
                values.systemUserInfo.map((i) => SystemUserInfo.SystemUserInfoRecord(i))
            );
        } else {
            modifiedValues.systemUserInfo = Immutable.List<SystemUserInfo.ISystemUserInfoRecord>();
        }
        if (values.systemTags) {
            modifiedValues.systemTags = Immutable.List<SystemTag.ISystemTagRecord>(
                values.systemTags.map((tag) => SystemTag.SystemTagRecord(tag))
            );
        } else {
            modifiedValues.systemTags = Immutable.List<SystemTag.ISystemTagRecord>();
        }
        if (values.lastSeen) {
            modifiedValues.lastSeen = Moment(values.lastSeen);
        }
        modifiedValues.contacts = values.contacts
            ? Immutable.List<UserContact.IUserContact>(
                  values.contacts.map((c) => UserContact.Record(c))
              )
            : Immutable.List<UserContact.IUserContact>();
        modifiedValues.systemBankAccounts = values.systemBankAccounts
            ? Immutable.List<ISystemBankAccount>(
                  values.systemBankAccounts.map((b: ISystemBankAccount) => new SystemBankAccount(b))
              )
            : Immutable.List<ISystemBankAccount>();
        modifiedValues.systemPinCodes = values.systemPinCodes
            ? Immutable.List<ISystemPinCode>(
                  values.systemPinCodes.map((c: ISystemPinCode) => new SystemPinCode(c))
              )
            : Immutable.List<ISystemPinCode>();
        super({ ...values, ...modifiedValues });
    }
}

export function UserRecord(values: IUserValues) {
    return new User(values);
}

export interface IUserDTO
    extends Pick<
        IUserValues,
        | "firstname"
        | "lastname"
        | "email"
        | "mobilePhone"
        | "locale"
        | "loginType"
        | "coAddress"
        | "address"
        | "zip"
        | "city"
        | "country"
        | "phone"
        | "imageUrl"
        | "imageFilename"
        | "image"
        | "imageUploadData"
        | "contacts"
        | "chrLogin"
        | "disableImpersonate"
        | "hasValidEmployment"
        | "userTitle"
    > {}

export interface ICreateUserDTO extends IUserDTO {
    username: string;
    password: string;
    userTitle?: string;
}

export interface IUpdateUserDTO extends IUserDTO {
    subject: string;
}

export interface IUserAssignedResultValues {
    emailSent: boolean;
    smsSent: boolean;
    email: string;
    mobile: string;
    error: string;
    userTitle: string;
}

export interface IUserAssignedResult
    extends IUserAssignedResultValues,
        Immutable.Map<string, any> {}

const UserAssignedResult = Immutable.Record({
    emailSent: false,
    smsSent: false,
    email: null,
    mobile: null,
    error: null,
    userTitle: "",
});

export function UserAssignedResultRecord(values: IUserAssignedResultValues): IUserAssignedResult {
    return <IUserAssignedResult>(<any>new UserAssignedResult(values));
}

export function formatName(firstName: string | undefined, lastName: string | undefined) {
    if (!firstName) return lastName;
    if (!lastName) return firstName;
    return `${firstName} ${lastName}`;
}

export function getUsername(user: IUserRecord) {
    return user.subject === user.username ? user.email : user.username;
}
