import { useWebBooking } from "api/api-hooks";
import { Box, Heading, Icon, Icons, Stack, Text } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

interface CancelBookingSuccessProps {
    isWaitList: boolean;
}

export function CancelBookingSuccess({ isWaitList }: CancelBookingSuccessProps) {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();

    const guests = webBooking?.guests;
    const date = webBooking?.start.format("dddd D MMMM");
    const startTime = webBooking?.start.format("HH:mm");
    const endTime = webBooking?.end.format("HH:mm");
    return (
        <Stack alignItems="center">
            <Box backgroundColor="#25AF72" padding="2px" borderRadius="50%" marginBottom="16px">
                <Icon icon={Icons.Success} fontSize="60px" color="white" />
            </Box>
            <Heading as="h1" fontWeight="500">
                {isWaitList ? t("waitList.waitListCanceledTitle") : t("bookingCanceledTitle")}
            </Heading>
            <Text textAlign="center">
                {isWaitList
                    ? t("waitList.waitListCanceledMessage")
                    : t("bookingCancelSuccessMessage", { guests, date, startTime, endTime })}
            </Text>
        </Stack>
    );
}
