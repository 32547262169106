enum ChargeStatusEnum {
    Unknown = 0,
    Created = 102,
    CardRegistered = 103,
    PaymentExpired = 113,
    PaymentFailed = 114,
    PaymentRefundFailed = 115,
    InactivatedByUser = 116,
}

export default ChargeStatusEnum;
