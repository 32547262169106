import moment from "moment-timezone";

import { RecordFactory } from "old/common/modules/recordFactory";

export enum BookedStatus {
    Undefined = 0,
    Available = 1,
    WaitList = 2,
    FullyBooked = 3,
}

export interface IDayState {
    day: IMoment;
    unitId: number;
    sectionId: number;
    state: number;
    availableSeats?: number[];
    waitListSeats?: number[];
    bookedStatus: BookedStatus;
}

const DayStateRecord = RecordFactory<IDayState>({
    day: null,
    sectionId: 0,
    state: 0,
    unitId: 0,
    bookedStatus: BookedStatus.Undefined,
    availableSeats: [],
    waitListSeats: [],
});

export class DayState extends DayStateRecord implements IDayState {
    constructor(values: Partial<IDayState>) {
        if (values.day) {
            values.day = moment(values.day).local();
        }

        super(values);
    }
}
