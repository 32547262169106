import config, { Environment } from "config/app.config";

export const getInitialCrashReportingSetting = (): boolean => {
    // Never report crashes while working in local dev environment
    if (config.environment === Environment.local || config.environment === Environment.devLocal)
        return false;

    // Report crashes for all other environments
    return true;
};

interface Favicons {
    favicon16: string;
    favicon32: string;
    favicon192: string;
    faviconSVG: string;
    favicon512: string;
    faviconApple: string;
}

export async function loadFavicons(
    environment: Environment | undefined
): Promise<Favicons | undefined> {
    if (!environment) return undefined;

    switch (environment) {
        case Environment.dev:
        case Environment.local:
        case Environment.devLocal:
            return {
                favicon16: (await import("../../assets/favicon/dev/favicon-16.png")).default,
                favicon32: (await import("../../assets/favicon/dev/favicon-32.png")).default,
                favicon192: (await import("../../assets/favicon/dev/icon-192.png")).default,
                favicon512: (await import("../../assets/favicon/dev/icon-512.png")).default,
                faviconSVG: (await import("../../assets/favicon/dev/icon.svg")).default,
                faviconApple: (await import("../../assets/favicon/dev/apple-touch-icon.png"))
                    .default,
            };
        case Environment.stage:
            return {
                favicon16: (await import("../../assets/favicon/stage/favicon-16.png")).default,
                favicon32: (await import("../../assets/favicon/stage/favicon-32.png")).default,
                favicon192: (await import("../../assets/favicon/stage/icon-192.png")).default,
                favicon512: (await import("../../assets/favicon/stage/icon-512.png")).default,
                faviconSVG: (await import("../../assets/favicon/stage/icon.svg")).default,
                faviconApple: (await import("../../assets/favicon/stage/apple-touch-icon.png"))
                    .default,
            };
        case Environment.prod:
        // No need to load favicons for production - prod is default set in index.hmtl
    }
}

export const setHTMLHeadAppProperties = async (environment: Environment) => {
    if (environment === Environment.prod) return;

    // Load the correct favicons based on the environment
    const favicons = await loadFavicons(environment);

    if (!favicons) return;

    const faviconLinks = [
        {
            id: "favicon16",
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: favicons.favicon16,
        },
        {
            id: "favicon32",
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: favicons.favicon32,
        },
        {
            id: "appleTouchIcon",
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: favicons.faviconApple,
        },
        { id: "faviconSVG", rel: "icon", type: "image/svg+xml", href: favicons.faviconSVG },
        {
            id: "favicon192",
            rel: "icon",
            type: "image/png",
            sizes: "192x192",
            href: favicons.favicon192,
        },
        {
            id: "favicon512",
            rel: "icon",
            type: "image/png",
            sizes: "512x512",
            href: favicons.favicon512,
        },
    ];

    faviconLinks.forEach(({ id, rel, type, sizes, href }) => {
        const link = document.getElementById(id) as HTMLLinkElement;
        link.id = id;
        link.rel = rel;
        if (type) link.type = type;
        if (sizes) link.setAttribute("sizes", sizes);
        link.href = href;
    });
};
