import { Alert } from "caspeco-casper-ui";
import { useWebBooking } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";

const NoShowChargeAlert = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();

    const payedAmount = webBooking.paymentEvents.last()?.payedAmount;

    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t("payment.noShowCharged", {
                amount: t("currency.onlyAmount", {
                    value: payedAmount,
                }),
            })}
        </Alert>
    );
};

export default NoShowChargeAlert;
