import { Heading, Stack } from "caspeco-casper-ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface IGreetingWrapperProps {
    name: string;
    children?: React.ReactNode;
}

const GreetingWrapper = ({ name, children }: IGreetingWrapperProps) => {
    const { t } = useTranslation();

    return (
        <Stack
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection="column"
            h="100%"
        >
            <div>
                <Heading as="h1" fontWeight="700">
                    {t("hi")} {name},
                </Heading>
                {children}
            </div>
        </Stack>
    );
};

export default GreetingWrapper;
