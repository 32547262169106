import { Alert } from "caspeco-casper-ui";
import AlertSkeleton from "components/skeletons/alert-skeleton";
import { useWebBooking } from "hooks";
import { useAvailableTimes } from "hooks/api";
import { useReservation } from "hooks/api/create-reservation";
import { useSelectedRule } from "hooks/use-selected-rule";
import { useTranslation } from "react-i18next";
import { useBookingStateContext } from "store";
import { CurrencyDisplay } from "translations/currency";
import { getPaymentCalculationTotal } from "utils/payment-utils";

const NoShowAlert = () => {
    const { t } = useTranslation();
    const selectedRule = useSelectedRule();
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);
    const reservation = useReservation();
    const { data: webBooking } = useWebBooking();

    if (availableTimes.isPending && !webBooking.webTimeRules) return <AlertSkeleton />;

    const guests = state.guests ? state.guests : webBooking.guests;
    const value = getPaymentCalculationTotal(
        selectedRule,
        guests,
        reservation.data?.articles?.filter((x) => x.isTable)
    );

    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t("noShowInfoAlert", {
                value,
                display: CurrencyDisplay.Symbol,
            })}
        </Alert>
    );
};

export default NoShowAlert;
