import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Text,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { WebBookingErrorCode, useError } from "context/error-context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBookingActionsContext } from "store";
import { setSearch } from "utils";

export default function ReservationFailedModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { errorCode, setErrorCode, isOpenReservationFailedModal, closeReservationFailedModal } =
        useError();
    const { setState } = useBookingActionsContext();

    const setMultipleSearch = (keys: string[]) => {
        keys.forEach((key) => setSearch(navigate, key));
    };

    const handleGoToSelectDate = () => {
        setState((prevState) => ({
            ...prevState,
            date: null,
            times: null,
        }));
        setMultipleSearch(["date", "r", "time", "end", "start"]);
        setErrorCode(WebBookingErrorCode.Undefined);
        closeReservationFailedModal();
    };

    const getMessageText = () => {
        switch (errorCode) {
            case WebBookingErrorCode.TemporaryUnavailable:
                return t("reservationFailed.message.notAvailable", { action: getActionText() });
            case WebBookingErrorCode.Undefined:
                return t("reservationFailed.message.unknown", { action: getActionText() });
            default:
                return;
        }
    };

    const getActionText = () => {
        switch (errorCode) {
            case WebBookingErrorCode.TemporaryUnavailable:
                return t("selectDateAgain");
            default:
                return t("selectDateAgain");
        }
    };

    const getButtonText = () => {
        switch (errorCode) {
            case WebBookingErrorCode.TemporaryUnavailable:
                return t("goToSelectDate");
            default:
                return t("goToSelectDate");
        }
    };
    return (
        <Modal
            isOpen={isOpenReservationFailedModal}
            onClose={handleGoToSelectDate}
            size="md"
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalHeader
                display="flex"
                justifyContent="space-between"
                p={ThemeSpaceVariable.Medium}
            >
                <Text fontWeight="medium" fontSize={ThemeSpaceVariable.Large}>
                    {t("reservationFailed.title")}
                </Text>
            </ModalHeader>
            <ModalBody
                display="flex"
                flexDirection="column"
                px={[ThemeSpaceVariable.Medium, ThemeSpaceVariable.Large]}
            >
                <Text>{getMessageText()}</Text>
            </ModalBody>
            <ModalFooter
                px={ThemeSpaceVariable.Medium}
                pt={ThemeSpaceVariable.Medium}
                pb={ThemeSpaceVariable.Large}
            >
                <Button
                    variant="primary"
                    size={["lg", "md"]}
                    alignSelf="flex-end"
                    onClick={handleGoToSelectDate}
                >
                    {getButtonText()}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
