import { AspectRatio, useBreakpointValue } from "@chakra-ui/react";
import {
    Box,
    Image as CaspecoImage,
    Flex,
    Skeleton,
    ThemeColorVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useExternalBookingSettingsQuery } from "hooks";
import Header from "layout/header";
import { Dispatch, SetStateAction, createContext, useEffect, useRef, useState } from "react";
import { CSSVarHeaderHeight } from "style-variables";

import "./layout.css";

const isIframe = window.self !== window.top;
let observer: ResizeObserver;

interface LayoutContext {
    setShowCoverImage?: Dispatch<SetStateAction<boolean>>;
}
export const LayoutContext = createContext<LayoutContext>({});

const Layout = ({ children }: { children: JSX.Element }) => {
    const [showCoverImage, setShowCoverImage] = useState(true);
    const { data: settings } = useExternalBookingSettingsQuery();
    const [isCoverImageLoaded, setIsCoverImageLoaded] = useState(false);

    const aspectRatio = useBreakpointValue({
        base: 1 / 1,
        md: 32 / 9,
    });

    const themeSettings = settings?.themeSettings;
    const coverImageUrl =
        aspectRatio === 1
            ? themeSettings?.coverImageOneByOne.imageUrl
            : themeSettings?.coverImageThirtyTwoByNine.imageUrl;
    const hasCoverImage = Boolean(
        themeSettings?.coverImageOneByOne.imageUrl &&
            themeSettings?.coverImageThirtyTwoByNine.imageUrl
    );

    const container = useRef(null);
    if (isIframe && container.current && !observer) {
        observer = new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;
            window.parent.postMessage(`caspeco-booking-height ${height}`, "*");
        });
        observer.observe(container.current);
    }

    useEffect(() => {
        if (!coverImageUrl) {
            return;
        }
        const handleCoverImageLoad = () => {
            setIsCoverImageLoaded(true);
        };
        const coverImage = new Image();
        coverImage.src = coverImageUrl;
        coverImage.onload = handleCoverImageLoad;

        return () => {
            coverImage.onload = null;
        };
    }, [coverImageUrl]);

    return (
        <Box
            ref={container}
            width="100%"
            minH="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            color={ThemeColorVariable.OnSurface}
        >
            <Header shouldHideCoverImage={!showCoverImage} hasCoverImage={hasCoverImage} />

            <Box minH={CSSVarHeaderHeight} w="full">
                {showCoverImage && hasCoverImage && (
                    <AspectRatio
                        position="relative"
                        ratio={aspectRatio}
                        width="full"
                        height="full"
                        overflow="hidden"
                    >
                        {isCoverImageLoaded ? (
                            <CaspecoImage
                                src={coverImageUrl}
                                alt="Cover image"
                                objectFit="cover"
                                width="full"
                                height="full"
                            />
                        ) : (
                            <Skeleton
                                startColor={ThemeColorVariable.NeutralContainer}
                                endColor={ThemeColorVariable.NeutralContainer}
                                width="full"
                                height="full"
                            />
                        )}
                    </AspectRatio>
                )}
            </Box>
            <Flex
                id="content"
                px={ThemeSpaceVariable.Medium}
                py={ThemeSpaceVariable.Large}
                w="100%"
                maxWidth="560px"
                justifyContent="center"
                flex="1"
            >
                <LayoutContext.Provider value={{ setShowCoverImage }}>
                    {children}
                </LayoutContext.Provider>
            </Flex>
        </Box>
    );
};

export default Layout;
