import {
    Button,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import React from "react";
import { Trans } from "react-i18next";

interface IPrivacyPolicyTextProps {
    onOpen: () => void;
}

const PrivacyPolicyText = ({ onOpen }: IPrivacyPolicyTextProps) => {
    const handlePolicyClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onOpen();
    };

    return (
        <Text
            mb={ThemeSpaceVariable.Medium}
            color={ThemeColorVariable.OnSurfaceSubdued}
            fontSize={ThemeFontSizeVariable.Small}
        >
            <Trans
                i18nKey="privacyPolicyLink"
                components={{
                    1: (
                        <Button
                            textDecoration="underline"
                            onClick={handlePolicyClicked}
                            variant="link"
                            size="sm"
                            color={ThemeColorVariable.OnSurfaceSubdued}
                        />
                    ),
                }}
            />
        </Text>
    );
};

export default PrivacyPolicyText;
