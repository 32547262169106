export enum AspectRatio {
    ThirtyTwoByNine = "THIRTY_TWO_BY_NINE",
    SixteenByNine = "SIXTEEN_BY_NINE",
    OneByOne = "ONE_BY_ONE",
}

export enum ConditionRulePaymentEnum {
    NoFee = 0,
    PrePayment = 1,
    NoShowFee = 2,
    NoShowFeeReservation = 3,
    WebShop = 4,
}

export enum PaymentCalculationMethod {
    NoPayment = 0,
    PaymentPerGuest = 1,
    PaymentPerBooking = 2,
    PaymentPerResource = 3,
}

export enum PaymentEventType {
    WebBookingCharge = 6,
    PaymentLinkCharge = 7,
    NoShow = 9,
    AddedPaidAmountLog = 13,
    NoShowLink = 14,
}
