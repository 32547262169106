import { WebBookingArticle } from "api/models/webBookingArticle";
import { PaymentEvent, WebBookingTimeRule } from "hooks/api/types";
import { t } from "i18next";
import { List } from "immutable";
import { PaymentCalculationMethod, PaymentEventType } from "types/enums";

export function getPaymentCalculationTotal(
    rule: WebBookingTimeRule,
    guests: number,
    resourceArticles: WebBookingArticle[]
): number {
    if (!rule) return 0;
    //@ts-expect-error In a transtion period where we are moving from paymentType to paymentModifier (The rule interface is not updated yet)
    const modifier = rule.paymentModifier || rule.paymentType;
    switch (modifier) {
        case PaymentCalculationMethod.PaymentPerBooking:
            return rule.paymentAmount;
        case PaymentCalculationMethod.PaymentPerGuest:
            return rule.paymentAmount * guests;
        case PaymentCalculationMethod.PaymentPerResource:
            return rule.paymentAmount * (resourceArticles?.length || 1);
        default:
            return rule.paymentAmount;
    }
}

export function getPaymentUnitTranslation(rule: WebBookingTimeRule): string {
    //@ts-expect-error In a transtion period where we are moving from paymentType to paymentModifier (The rule interface is not updated yet)
    const modifier = rule.paymentModifier || rule.paymentType;
    switch (modifier) {
        case PaymentCalculationMethod.PaymentPerBooking:
            return t("payment.unit.booking");
        case PaymentCalculationMethod.PaymentPerGuest:
            return t("payment.unit.guest");
        case PaymentCalculationMethod.PaymentPerResource:
            return t("payment.unit.resource");
        default:
            return t("payment.unit.guest");
    }
}

export function payedAmountSum(paymentEvents: List<PaymentEvent>): number {
    if (!paymentEvents) return 0;
    return paymentEvents
        .filter((p) => p.type !== PaymentEventType.AddedPaidAmountLog)
        .map((pe) => pe.payedAmount)
        .reduce<number>((prev, cur) => prev + cur, 0);
}
