export enum Environment {
    prod = "prod",
    dev = "dev",
    local = "local",
    devLocal = "dev-local",
    stage = "stage",
}

interface IEnvironment {
    baseUrl: string;
    environment: Environment | null;
}

function getEnvironment(name: string): Environment | null {
    switch (name) {
        case "prod":
            return Environment.prod;
        case "dev":
            return Environment.dev;
        case "dev-local":
            return Environment.devLocal;
        case "stage":
            return Environment.stage;
        case "local":
            return Environment.local;
        default:
            return null;
    }
}

export const config: IEnvironment = {
    baseUrl: import.meta.env.VITE_APP_BASE_URL ?? "",
    environment: getEnvironment(import.meta.env.VITE_APP_ENV_NAME ?? ""),
};

export default config;
