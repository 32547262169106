const PROD_BASE_URL = "https://cloud.caspeco.se/api";

// We can also specify an .env variable for the API URL
const ENV_BASE_URL = import.meta.env.VITE_BASE_URL;

// We can alos get base url from localeStorage
const LOCAL_BASE_URL = localStorage.getItem("BASE_URL");

// export BASE_URL, env overrides prod, local overrides env
export const BASE_URL = LOCAL_BASE_URL || ENV_BASE_URL || PROD_BASE_URL;

const hostWithoutPort = location?.host?.split(":")[0];
export const isDev = hostWithoutPort === "localhost";
