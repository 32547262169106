import moment from "moment";
import { RecordFactory } from "old/common/modules/recordFactory";

export const enum WaitListOfferStatus {
    Unavailable = 0,
    Available = 1,
}

export interface IWaitListOffer {
    startTime: IMoment;
    offerStatus: WaitListOfferStatus;
}

const WaitListOfferRecord = RecordFactory<IWaitListOffer>({
    startTime: moment.invalid(),
    offerStatus: WaitListOfferStatus.Unavailable,
});

export class WaitListOffer extends WaitListOfferRecord implements IWaitListOffer {
    constructor(values?: Partial<IWaitListOffer>) {
        if (values) {
            values.startTime = moment(values.startTime, "YYYY-MM-DDTHH:mm:ss");
        }
        super(values);
    }
}
