import {
    Box,
    Flex,
    Heading,
    Icon,
    Icons,
    Stack,
    Text,
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

export function WaitListSuccess() {
    const { t } = useTranslation();

    const border = `${ThemeBorderVariable.Small} ${ThemeColorVariable.Success}`;
    return (
        <Stack alignItems="center" gap={ThemeSpaceVariable.Medium}>
            <Flex direction="column" alignItems="center" py={ThemeSpaceVariable.Medium}>
                <Box
                    backgroundColor={ThemeColorVariable.SuccessContainer}
                    border={border}
                    padding="5px"
                    borderRadius="50%"
                    mb={ThemeSpaceVariable.Medium}
                >
                    <Icon
                        icon={Icons.ModuleSurvey}
                        fontSize="50px"
                        color={ThemeColorVariable.Success}
                    />
                </Box>
                <Heading
                    as="h1"
                    maxW="340"
                    fontWeight="500"
                    textAlign="center"
                    mb={ThemeSpaceVariable.Small}
                >
                    {t("waitList.waitListSuccessTitle")}
                </Heading>
                <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                    {t("waitList.waitListSuccessMessage")}
                </Text>
            </Flex>
        </Stack>
    );
}
