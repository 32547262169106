import { Heading, Stack, Text } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

export interface GreetingProps {
    name: string;
    isWaitList: boolean;
}

export default function Greeting({ name, isWaitList }: GreetingProps) {
    const { t } = useTranslation();

    return (
        <Stack
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection="column"
            style={{ height: "100%" }}
        >
            <div>
                <Heading as={"h1"} fontWeight="700">
                    {t("hi")} {name},
                </Heading>
                <Text>
                    {isWaitList ? t("waitList.waitListDetailsMessage") : t("viewBookingDetails")}
                </Text>
            </div>
        </Stack>
    );
}
