import { Heading, Icon, Icons, Stack } from "caspeco-casper-ui";
import { PhoneNumberLink } from "components/links/phone-number-link";
import { useTranslation } from "react-i18next";

export function CancelBookingFailed() {
    const { t } = useTranslation();
    return (
        <Stack alignItems="center">
            <Icon icon={Icons.SlightlySad} color="#242236" fontSize="60px" />
            <Heading as="h1">{t("errorInformation.somethingWrong")}</Heading>
            <PhoneNumberLink />
        </Stack>
    );
}
