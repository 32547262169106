import { Alert } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

const WaitListPrepaymentAlert = () => {
    const { t } = useTranslation();
    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t("payment.waitListWithPrepaymentInfo")}
        </Alert>
    );
};

export default WaitListPrepaymentAlert;
