import {
    Box,
    Button,
    Heading,
    Icon,
    Icons,
    Stack,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import React from "react";

interface ErrorTemplateProps {
    btnCallback?: () => void;
    btnText?: string;
    icon: Icons | JSX.Element;
    title: string;
    message: string;
    isBtnLoading?: boolean;
}

const ErrorTemplate = ({
    btnCallback,
    btnText,
    icon,
    title,
    message,
    isBtnLoading,
}: ErrorTemplateProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={ThemeSpaceVariable.Medium}
            mt={ThemeSpaceVariable.Large}
        >
            {React.isValidElement(icon) ? (
                icon
            ) : (
                <Icon icon={icon as Icons} color="#242236" fontSize="60px" />
            )}
            <Stack textAlign="center" spacing={ThemeSpaceVariable.Small}>
                <Heading fontWeight="medium" as="h2">
                    {title}
                </Heading>
                <Text fontSize={ThemeFontSizeVariable.Large} textAlign="center">
                    {message}
                </Text>
                {btnCallback && (
                    <Button variant="primary" onClick={btnCallback} isLoading={isBtnLoading}>
                        {btnText}
                    </Button>
                )}
            </Stack>
        </Box>
    );
};

export default ErrorTemplate;
