import { useWebBooking } from "api/api-hooks";
import NoShowChargeAlert from "components/alerts/no-show-charge-alert";
import NoShowAlert from "components/details-form/no-show-alert";
import PrePaymentAlert from "components/details-form/pre-payment-alert";
import WaitListNoShowAlert from "components/wait-list/wait-list-no-show-alert";
import WaitListPrepaymentAlert from "components/wait-list/wait-list-prepayment-alert";
import { ConditionRulePayment as ChargeType } from "old/models/webRuleConditionRulePaymentEnum";
import { isNoShowBooking, isWaitList } from "utils/booking";

interface InfromationAlertSectionProps {
    shouldShowConfirmWaitListBtn: boolean;
}

const MyBookingAlert = ({ shouldShowConfirmWaitListBtn }: InfromationAlertSectionProps) => {
    const { data: booking } = useWebBooking();
    const isNoShow = isNoShowBooking(booking);
    const isWaitListBooking = isWaitList(booking);

    const rule = booking.webTimeRules?.first();
    const isPrePayment = rule?.didShowUpRuleType === ChargeType.PrePayment;
    const isNoShowFee = rule?.didShowUpRuleType === ChargeType.NoShowFee;

    const hasNoShowCharge = booking.paymentEvents?.last()?.payedAmount > 0 && isNoShow;

    if (hasNoShowCharge) {
        return <NoShowChargeAlert />;
    }

    if (isNoShowFee && isWaitListBooking && !shouldShowConfirmWaitListBtn) {
        return <WaitListNoShowAlert />;
    }

    if (isPrePayment && isWaitListBooking && !shouldShowConfirmWaitListBtn) {
        return <WaitListPrepaymentAlert />;
    }

    if (isNoShowFee && shouldShowConfirmWaitListBtn) {
        return <NoShowAlert />;
    }

    if (isPrePayment && shouldShowConfirmWaitListBtn) {
        return <PrePaymentAlert />;
    }

    return null;
};

export default MyBookingAlert;
