import { List, Map, Record } from "immutable";

// ----------- SystemTag -----------
export interface ISystemTagValues {
    id: number;
    name: string;
}

export interface ISystemTagRecord extends ISystemTagValues, Map<string, any> {}

export interface ISystemTagDesc {
    systemTag: ISystemTagRecord;
    desc: string;
}

const SystemTag = Record({
    id: -1,
    name: "",
});

export function SystemTagRecord(values: ISystemTagValues): ISystemTagRecord {
    return <ISystemTagRecord>(<any>new SystemTag(<any>values));
}

export function getIntersectingTags(
    systemTags: List<ISystemTagRecord>,
    systemTagDescs: List<ISystemTagDesc>
): List<ISystemTagDesc> {
    let intersection = List<ISystemTagDesc>();
    if (systemTags && systemTagDescs) {
        systemTags.forEach((st) => {
            const tag = systemTagDescs.find((t) => t.systemTag.id === st.id);
            if (tag) intersection = intersection.push(tag);
        });
    }
    return intersection;
}
