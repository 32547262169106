import { Box } from "caspeco-casper-ui";

export interface SpaceBetweenProps {
    children?: React.ReactNode;
}

export const SpaceBetween = ({ children }: SpaceBetweenProps) => {
    return (
        <Box display="flex" justifyContent="space-between" w="full">
            {children}
        </Box>
    );
};
