import { RecordFactory } from "old/common/modules/recordFactory";

export interface IWhiteLabelColors {
    background: string;
    onBackground: string;
    onBackgroundSubdued: string;
    onBackgroundBorder: string;
    backgroundDisabled: string;
    onBackgroundHeader: string;
    surface: string;
    onSurface: string;
    onSurfaceSubdued: string;
    onSurfaceBorder: string;
    surfaceDisabled: string;
    onSurfaceHeader: string;
    primary: string;
    primaryHover: string;
    primaryActive: string;
    onPrimary: string;
    primaryContainer: string;
    onPrimaryContainer: string;
    secondary: string;
    secondaryHover: string;
    secondaryActive: string;
    onSecondary: string;
    secondaryContainer: string;
    onSecondaryContainer: string;
    accent: string;
    accentHover: string;
    accentActive: string;
    accentHoverAlpha: string;
    accentActiveAlpha: string;
    onAccent: string;
    accentContainer: string;
    onAccentContainer: string;
    info: string;
    infoHover: string;
    infoActive: string;
    onInfo: string;
    infoContainer: string;
    onInfoContainer: string;
    success: string;
    successHover: string;
    successActive: string;
    onSuccess: string;
    successContainer: string;
    onSuccessContainer: string;
    warning: string;
    warningHover: string;
    warningActive: string;
    onWarning: string;
    warningContainer: string;
    onWarningContainer: string;
    error: string;
    errorHover: string;
    errorActive: string;
    onError: string;
    errorContainer: string;
    onErrorContainer: string;
    neutral: string;
    neutralHover: string;
    neutralActive: string;
    onNeutral: string;
    neutralContainer: string;
    onNeutralContainer: string;
}

const WhiteLabelColorsRecord = RecordFactory<IWhiteLabelColors>({
    background: "",
    onBackground: "",
    onBackgroundSubdued: "",
    onBackgroundBorder: "",
    backgroundDisabled: "",
    onBackgroundHeader: "",
    surface: "",
    onSurface: "",
    onSurfaceSubdued: "",
    onSurfaceBorder: "",
    surfaceDisabled: "",
    onSurfaceHeader: "",
    primary: "",
    primaryHover: "",
    primaryActive: "",
    onPrimary: "",
    primaryContainer: "",
    onPrimaryContainer: "",
    secondary: "",
    secondaryHover: "",
    secondaryActive: "",
    onSecondary: "",
    secondaryContainer: "",
    onSecondaryContainer: "",
    accent: "",
    accentHover: "",
    accentActive: "",
    accentHoverAlpha: "",
    accentActiveAlpha: "",
    onAccent: "",
    accentContainer: "",
    onAccentContainer: "",
    info: "",
    infoHover: "",
    infoActive: "",
    onInfo: "",
    infoContainer: "",
    onInfoContainer: "",
    success: "",
    successHover: "",
    successActive: "",
    onSuccess: "",
    successContainer: "",
    onSuccessContainer: "",
    warning: "",
    warningHover: "",
    warningActive: "",
    onWarning: "",
    warningContainer: "",
    onWarningContainer: "",
    error: "",
    errorHover: "",
    errorActive: "",
    onError: "",
    errorContainer: "",
    onErrorContainer: "",
    neutral: "",
    neutralHover: "",
    neutralActive: "",
    onNeutral: "",
    neutralContainer: "",
    onNeutralContainer: "",
});

export class WhiteLabelColors extends WhiteLabelColorsRecord implements IWhiteLabelColors {
    constructor(values?: Partial<IWhiteLabelColors>) {
        super(values);
    }
}
