import { createContext, useContext } from "react";
import { IWebBookingViewStoreState } from "old/stores/webBookingViewStore";

export const context = createContext<IWebBookingViewStoreState | null>(null);

export const useBookingViewStore = () => {
    const contextHook = useContext(context);
    if (contextHook === undefined) {
        throw new Error("Could not init view store context");
    }
    return contextHook;
};
