import * as Immutable from "immutable";

// ----------- SystemUserInfo -----------
export interface ISystemUserInfo {
    systemName?: string;
    workEmail: string;
    workMobilePhone: string;
    userTitle?: string;
    isManuallyConnected: boolean;
}

export interface ISystemUserInfoRecord extends ISystemUserInfo, Immutable.Map<string, any> {}

const SystemUserInfo = Immutable.Record({
    systemName: "",
    workMobilePhone: "",
    userTitle: "",
    workEmail: "",
    isManuallyConnected: false,
});

export function SystemUserInfoRecord(values: ISystemUserInfo): ISystemUserInfoRecord {
    return <ISystemUserInfoRecord>(<any>new SystemUserInfo(<any>values));
}
