import { Flex } from "caspeco-casper-ui";

import "./spinner.less";

export default function Spinner() {
    return (
        <Flex justifyContent="center" alignItems="center" flex="1">
            <div className="spinner" />
        </Flex>
    );
}
