import { useEffect, useState } from "react";

export const useMobileView = (): boolean => {
    const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 480);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 480);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobileView;
};

export default useMobileView;
