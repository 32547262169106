import { webbookingSettingFlags } from "old/models/unitMetaData";
import { RecordFactory } from "old/common/modules/recordFactory";

export interface IUnitMetaDataExternal {
    activateWebChildren: boolean;
    bookingWindow: number;
    confirmEmail: boolean;
    confirmSms: boolean;
    contactSetting: any;
    cssUrl: string;
    id: number;
    maxWebGuests: number;
    messageCompleted: string;
    messageExceededGuests: string;
    unitId: number;
    name: string;
    colorTheme: string;
    showTimeFilter: boolean;
    binaryFlags: webbookingSettingFlags;
}

const UnitMetaDataExternalRecord = RecordFactory<IUnitMetaDataExternal>({
    id: -1,
    unitId: -1,
    messageExceededGuests: "",
    messageCompleted: "",
    cssUrl: "",
    name: "",
    maxWebGuests: -1,
    confirmEmail: false,
    confirmSms: false,
    activateWebChildren: false,
    bookingWindow: -1,
    contactSetting: {},
    colorTheme: "",
    showTimeFilter: false,
    binaryFlags: webbookingSettingFlags.None,
});

export class UnitMetaDataExternal
    extends UnitMetaDataExternalRecord
    implements IUnitMetaDataExternal
{
    constructor(values?: Partial<IUnitMetaDataExternal>) {
        super(values);
    }
}
