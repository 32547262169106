import { PaymentMethodType } from "api/models/webBookingPaymentTerminalParameters";
import { WebBookingTimeRule } from "hooks/api/types";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { ConditionRulePaymentEnum as paymentType } from "types/enums";
import { State } from "types/store";

export const getDefaultPaymentMethod = (
    settings: ExternalBookingSettings,
    rule: WebBookingTimeRule,
    state: State
) => {
    if (state?.payment && state.payment.payMethod !== null) {
        return state.payment.payMethod;
    }

    if (rule.payment === paymentType.NoShowFee) {
        return null;
    }
    if (settings.paymentMethods) {
        const mobile = settings.paymentMethods.find(
            (method) => method === PaymentMethodType.Mobile
        );
        if (mobile) {
            return mobile;
        }
    }

    return PaymentMethodType.CreditCard;
};

// Doing an extra becasue theres a type/name missmatch. Need to merge when my-booking and wizard use the same names/types.
export const getDefaultPaymentMethod_Temporary = (
    settings: ExternalBookingSettings,
    isPrepayment: boolean,
    state: State
) => {
    if (!isPrepayment) return null;

    if (state?.payment && state.payment.payMethod !== null) {
        return state.payment.payMethod;
    }

    if (settings.paymentMethods) {
        const mobile = settings.paymentMethods.find(
            (method) => method === PaymentMethodType.Mobile
        );
        if (mobile) {
            return mobile;
        }
    }

    return PaymentMethodType.CreditCard;
};
